import MainRouter from '../src/MainRouter'
import {BrowserRouter} from 'react-router-dom'


const App = () => (

<BrowserRouter>
   <MainRouter/>
</BrowserRouter>

)


export default App;
